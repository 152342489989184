import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Form, Input, DatePicker, Modal, Table, Space, Tooltip, Tag } from 'antd';
import { PageContainer, ProTable } from '@ant-design/pro-components';
import './index.scss';
import { request } from '@/utils';
import { EyeTwoTone,DiffOutlined } from '@ant-design/icons';


const { RangePicker } = DatePicker;

const Records = () => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleRecords, setModalVisibleRecords] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalDataRecords, setModalDataRecords] = useState([]);
  const formRef = useRef();

  // 假设tableParams是一个包含pagination属性的状态对象
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1, // 当前页数
      pageSize: 10, // 每页记录数
      total: 0,
    },
  });

  const handlePaginationChange = (newPageNum, newPageSize) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: {
        current: newPageNum,
        pageSize: newPageSize,
      },
    }));
  };


  const columnsDetails = [
    {
      title: '名前',
      width: 150,
      dataIndex: 'cname',
      className: 'custom-column',
    },
    {
      title: '英語名前',
      width: 150,
      dataIndex: 'ename',
      className: 'custom-column',
    },
    {
      title: '性別',
      width: 50,
      dataIndex: 'gender',
      className: 'custom-column',
    },
    {
      title: '生年月日',
      width: 150,
      dataIndex: 'birthday',
      className: 'custom-column',
    },
    {
      title: 'パスポート',
      width: 150,
      dataIndex: 'passport',
      className: 'custom-column',
    },
  ];

  const columnsRecordsDetails = [
    {
      title: '状態',
      width: 50,
      dataIndex: 'code',
      className: 'custom-column',
      render: (value, record) => {
        if (record.isEmpty) {
          return <span>&nbsp;</span>; // 如果是空行，显示空白
        }

        let color;
        let text;

        switch (value) {
          case "1":
            color = 'green';
            text = '新規';
            break;
          case "2":
            color = 'gold';
            text = '変更';
            break;
          case "3":
            color = 'red';
            text = '削除';
            break;
          default:
            color = 'default';
            text = '未定義';
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: '操作者',
      width: 150,
      dataIndex: 'user',
      className: 'custom-column',
    },
    {
      title: '操作時間',
      width: 150,
      dataIndex: 'time',
      className: 'custom-column',
    },
  ];

  const tableParamsString = useMemo(() => JSON.stringify(tableParams), [tableParams]);

  useEffect(() => {
    handleSearch();
  }, [tableParamsString]);

  const handleSearch = async () => {
    try {
      const { tourNo, updateTime } = tableParams;
      const startTime = updateTime ? `${updateTime[0].format('YYYY-MM-DD')} 00:00:01` : null;
      const endTime = updateTime ? `${updateTime[1].format('YYYY-MM-DD')} 23:59:59` : null;

      const requestParms = {
        ...tableParams.pagination,
        tourNo,
        startTime,
        endTime,
      };

      // 发送请求
      const response = await request.post('/delLogList', requestParms);

      const processedData = response.data.list.map(item => {
        // 如果 entryTime 存在且为字符串类型，则解析为对象
        const entryTime = (item.entryTime && typeof item.entryTime === 'string') ? JSON.parse(item.entryTime) : item.entryTime;
        const hotelTime = (item.hotelTime && typeof item.hotelTime === 'string') ? JSON.parse(item.hotelTime) : item.hotelTime;

        if (entryTime && Array.isArray(entryTime)) {
          const formattedEntryTime = entryTime.map(timeObj => {
            const { stayDate, stayPlace } = timeObj;

            const formattedDate = stayDate[0] + ' => ' + stayDate[stayDate.length - 1];

            return formattedDate;

          });

          item.entryTime = formattedEntryTime.join('；');
        }

        if (hotelTime && Array.isArray(hotelTime)) {
          const formattedEntryTime = hotelTime.map(timeObj => {
            const { stayDate, stayPlace } = timeObj;
            const formattedDate = stayDate[0] + ':'; // 获取 stayDate 数组的第一个时间，并加上分号
            return formattedDate + stayPlace;
          });

          item.hotelTime = formattedEntryTime.join('；');
        }
        return {
          ...item,
          entryTime: item.entryTime,
          hotelTime: item.hotelTime,
          persons: (item.persons ? JSON.parse(item.persons) : null),
          records: (item.records ? JSON.parse(item.records) : null),
        };
      });

      // 更新数据
      setDataList(processedData);

      // 更新分页参数
      setTableParams(prevParams => ({
        ...prevParams,
        pagination: {
          ...prevParams.pagination,
          total: response.data.total,
        },
      }));
    } catch (error) {
      console.error('Error searching data:', error);
    }
  };

  // 处理点击“ツアー详情”按钮时的逻辑
  const handleShowModal = (record) => {
    setModalData(record.persons); // 将解析后的数据传入模态框
    setModalVisible(true); // 显示模态框
  };

  // 处理点击“履歴”按钮时的逻辑
  const handleShowModalRecords = (record) => {
    if (!record.records) return;
    setModalDataRecords(record.records); // 将解析后的数据传入模态框
    setModalVisibleRecords(true); // 显示模态框
  };

  // 默认显示8行数据
  const fillDataWithEmptyRows = (data = [], rowCount) => {
    const filledData = [...data];
    while (filledData.length < rowCount) {
      filledData.push({ key: `empty-${filledData.length}`, isEmpty: true });
    }
    return filledData;
  };

  const dataSourceWithEmptyRows = fillDataWithEmptyRows(modalDataRecords, 8);

  const handleFilterChange = (newFilters) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        current: 1, // 其他筛选参数变化时重置 current 为 1
      },
      ...newFilters,
    }));
  };

  // 关闭模态框
  const handleCloseModal = () => {
    setModalVisible(false);
    setModalVisibleRecords(false);
  };

  const columns = [
    {
      title: 'ツアー名',
      dataIndex: 'tourNo',
      width: 340,
      className: 'custom-column',
    },
    {
      title: 'ガイド',
      dataIndex: 'guideName',
      width: 190,
      className: 'custom-column',
    },
    {
      title: '人数',
      dataIndex: 'peopleNum',
      width: 60,
      className: 'custom-column',
      render: (_, record) => (
        record.persons.length
      )
    },
    {
      title: 'ツアー時間',
      dataIndex: 'entryTime',
      width: 380,
      className: 'custom-column',
      ellipsis: {
        showTitle: false,
      },
      render: (stayDate) => {
        // 获取props属性中的children内容
        const stayDateChildren = stayDate.props.children;
        const formattedStayDate = stayDateChildren.split('；').join('\n');
        return (
          <Tooltip
            placement="top"
            title={<pre>{formattedStayDate}</pre>}// 在 Tooltip 中显示完整内容
            overlayClassName="custom-tooltip"
            mouseEnterDelay={1}
            color="blue"
          >
            {stayDate}
          </Tooltip>
        );
      },
    },
    {
      title: '操作者',
      dataIndex: 'updateUser',
      width: 120,
      className: 'custom-column',
    },
    {
      title: '操作時間',
      dataIndex: 'updateTime',
      width: 150,
      className: 'custom-column',
    },
    {
      title: '操作',
      width: 150,
      align: 'center',
      render: (_, record) => (
        <Space size="small">
          <Button icon={<EyeTwoTone />} onClick={() => handleShowModal(record)} />
          <Button icon={<DiffOutlined style={{ color: 'rgb(22, 119, 255)' }} />} onClick={() => handleShowModalRecords(record)} style={{
            color: record.records ? 'blue' : 'grey',
            cursor: record.records ? 'pointer' : 'not-allowed'
          }}
            disabled={!record.records} />
        </Space>
      )
    }
  ];

  return (

    <PageContainer style={{ marginTop: '-50px' }}>
      <div className="custom-div">
        <div className='select-menu-div'>
          <Form
            ref={formRef}
            onFinish={handleFilterChange}
            layout="inline"
          >
            <Form.Item name="tourNo">
              <Input placeholder="ツアー名" />
            </Form.Item>
            <Form.Item name="updateTime">
              <RangePicker placeholder={['開始操作時間', '終了操作時間']} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="custom-button">検索</Button>
            </Form.Item>
          </Form>
        </div>

        <div className='list-data-div'>
          <ProTable className='custom-column'
            headerTitle=""
            columns={columns}
            rowKey="key"
            dataSource={dataList}
            pagination={{
              current: tableParams.pagination.current,
              pageSize: tableParams.pagination.pageSize,
              total: tableParams.pagination.total,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
              showTotal: (total) => `計 ${total}`,
              onChange: (current, pageSize) => handlePaginationChange(current, pageSize),
            }}
            options={false}
            search={false}
            rowClassName="compact-row"
          />
        </div>
      </div>
      <Modal
        title="詳細"
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="80%" // 设置模态框宽度为屏幕宽度的80%
      >
        <Table
          columns={columnsDetails}
          dataSource={modalData}
          pagination={false} // 去掉分页
          scroll={{ y: 'calc(90vh - 150px)', x: 'max-content' }} // 使用 calc() 函数计算表格的高度
        />
      </Modal>

      <Modal
        title="詳細履歴"
        open={modalVisibleRecords}
        onCancel={handleCloseModal}
        footer={null}
        width="60%" // 设置模态框宽度为屏幕宽度的80%
      >
        <Table
          columns={columnsRecordsDetails}
          dataSource={dataSourceWithEmptyRows}
          pagination={false} // 去掉分页
          scroll={{ y: 'calc(90vh - 150px)', x: 'max-content' }} // 使用 calc() 函数计算表格的高度
        />
      </Modal>
    </PageContainer>

  );
};

export default Records;
