import './index.scss'
import { Card, Form, Input, Button, message } from 'antd'
import logo from '@/assets/logo.png'
import { useDispatch } from 'react-redux'
import { fetchLogin } from '@/store/modules/user'
import { useNavigate } from 'react-router-dom'
import { getToken } from '../../utils'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onFinish = async (values) => {
    console.log(values)
    await dispatch(fetchLogin(values))
    // 跳转首页
    if (getToken()) {
      navigate('/');
      message.success('ログインしました')
    }
  }

  return (
    <div className="login">
      <Card className="login-container">
        <div className="login-header">
          <img className="login-logo" src={logo} alt="" />
          {/* 登录表单 */}
          <div className='login-title'>リベート計算システム</div>
        </div>
        <Form validateTrigger="onBlur" onFinish={onFinish}>
          <Form.Item name="userLoginName"
            rules={[
              {
                required: true,
                message: 'アカウントを入力してください!',
              },
            ]}>
            <Input size="large" placeholder="アカウントを入力してください" />
          </Form.Item>
          <Form.Item name="userPassword"
            rules={[
              {
                required: true,
                message: 'パスワードを入力してください!',
              },
            ]}>
            <Input size="large" placeholder="パスワードを入力してください" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
           　 ログイン
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default Login