import Layout from "@/pages/Layout";
import Login from "@/pages/Login";
import { createBrowserRouter } from 'react-router-dom'
import { AuthRoute } from "@/components/AuthRoute"
import Home from "@/pages/Home";
import Tourupload from "@/pages/Upload";
import Records from "@/pages/Records";
const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthRoute><Layout /></AuthRoute>,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'tourupload',
        element: <Tourupload />
      },
      {
        path: 'records',
        element: <Records />
      },
    ]
  },
  {
    path: '/login',
    element: <Login />,
  },
])

export default router