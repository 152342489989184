import { createSlice } from '@reduxjs/toolkit'
import { removeToken, request } from '@/utils'
import { setToken as _setToken, getToken } from '@/utils'
import { message } from 'antd'
const userStore = createSlice({
  name: 'user',
  // 数据状态，两个数据
  initialState: {
    token: getToken() || '',
    userInfo: []
  },
  // 同步修改方法，设置它们的修改方法
  reducers: {
    setToken(state, action) {
      state.token = action.payload
      _setToken(action.payload)
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload
    },
    clearUserInfo(state) {
      state.token = ''
      state.userInfo = {}
      removeToken()
    }
  }
})

// 解构出actionCreater
const { setToken, setUserInfo, clearUserInfo } = userStore.actions

// 获取reducer函数
const userReducer = userStore.reducer

const fetchLogin = (loginForm) => {
  return async (dispatch) => {
    const res = await request.post('/login', loginForm)
    if (res.data) {
      dispatch(setToken(res.data))
    } else {
      message.error('パスワードまたはアカウントが間違っています')
    }
  }
}

const fetchUserInfo = () => {
  return async (dispatch) => {
    const res = await request.get('/getUserByToken')
    dispatch(setUserInfo(res.data))
  }
}

export { setToken, fetchLogin, fetchUserInfo, clearUserInfo }

export default userReducer